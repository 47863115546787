import { io } from "socket.io-client";

const socket = io("https://jellyfish-app-3njoh.ondigitalocean.app/");
//const socket = io("http://localhost:8080/");

var reactContainer = document.getElementById("react-container");
var voteContainer = document.getElementById("vote-container");
var conceptContainer = document.getElementById("concept-container");


var wowButton1 = document.getElementById("wow-button-1");
var wowButton2 = document.getElementById("wow-button-2");
var wowButton3 = document.getElementById("wow-button-3");
var wowButton4 = document.getElementById("wow-button-4");
var wowButton5 = document.getElementById("wow-button-5");
var wowButton6 = document.getElementById("wow-button-6");


var voteButton1 = document.getElementById("vote-button-1");
var voteButton1Text = document.getElementById("vote-button-1-text");
var voteButton2 = document.getElementById("vote-button-2");
var voteButton2Text = document.getElementById("vote-button-2-text");
var voteButton3 = document.getElementById("vote-button-3");
var voteButton3Text = document.getElementById("vote-button-3-text");
var voteButton4 = document.getElementById("vote-button-4");
var voteButton4Text = document.getElementById("vote-button-4-text");


var conceptInput = document.getElementById("concept-input");
var conceptSubmitButton = document.getElementById("concept-submit-button");


socket.on('connect', () => {
    console.log("connected!");
});

socket.on('current page vote', (data) => {
    reactContainer.style.display = "none";
    voteContainer.style.display = "grid";
    conceptContainer.style.display = "none";
    voteButton1Text.innerText = data.choice1.toUpperCase();
    voteButton2Text.innerText = data.choice2.toUpperCase();

    if (data.choice3 != null && data.choice3 != "") {
        voteButton1.classList.remove("two-vote");
        voteButton2.classList.remove("two-vote");


        voteButton3.style.display = "flex";
        voteButton4.style.display = "flex";
        voteButton3Text.innerText = data.choice3.toUpperCase();
        voteButton4Text.innerText = data.choice4.toUpperCase();
        voteContainer.style.gridTemplateColumns = "1fr 1fr";
    } 
    else {
        voteButton1.classList.add("two-vote");
        voteButton2.classList.add("two-vote");

        voteButton3.style.display = "none";
        voteButton4.style.display = "none";
        voteContainer.style.gridTemplateColumns = "auto";
    }
});

socket.on('current page concept', (data) => {
    reactContainer.style.display = "none";
    voteContainer.style.display = "none";
    conceptContainer.style.display = "flex";
});

socket.on('current page react', (data) => {
    reactContainer.style.display = "grid";
    voteContainer.style.display = "none";
    conceptContainer.style.display = "none";
});

wowButton1.onclick = () => { socket.emit("send reaction", { userId: socket.id, option: 0 }); };
wowButton2.onclick = () => { socket.emit("send reaction", { userId: socket.id, option: 1 }); };
wowButton3.onclick = () => { socket.emit("send reaction", { userId: socket.id, option: 2 }); };
wowButton4.onclick = () => { socket.emit("send reaction", { userId: socket.id, option: 3 }); };
wowButton5.onclick = () => { socket.emit("send reaction", { userId: socket.id, option: 4 }); };
wowButton6.onclick = () => { socket.emit("send reaction", { userId: socket.id, option: 5 }); };

voteButton1.onclick = () => { socket.emit("send vote", { userId: socket.id, option: 1 }); };
voteButton2.onclick = () => { socket.emit("send vote", { userId: socket.id, option: 2 }); };
voteButton3.onclick = () => { socket.emit("send vote", { userId: socket.id, option: 3 }); };
voteButton4.onclick = () => { socket.emit("send vote", { userId: socket.id, option: 4 }); };

conceptSubmitButton.onclick = () => {
    socket.emit("send concept", { userId: socket.id, text: conceptInput.value });
    conceptInput.value = "";
};